import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const Masthead = () => {
    const data = useStaticQuery(graphql`
        query {
            images: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { glob: "**/content/masthead/*.md" }
                }
                sort: { fields: frontmatter___order, order: ASC }
            ) {
                edges {
                    node {
                        frontmatter {
                            alt
                            src {
                                childImageSharp {
                                    gatsbyImageData(width: 2000)
                                }
                            }
                        }
                    }
                }
            }
            chevron: file(relativePath: { eq: "chevron-down.svg" }) {
                publicURL
            }
        }
    `);
    

    const images = data.images.edges.map((image) => {
        return {
            src: getImage(image.node.frontmatter.src),
            alt: image.node.frontmatter.alt
        }        
    })

    const chevronSrc = data.chevron.publicURL;

    useEffect(() => {
        const swiper = new Swiper('.swiper-masthead', {
            modules: [Autoplay, EffectFade],
            autoplay: {
                delay: 5000,
            },
            loop: true,
            effect: 'fade',
        });
    }, []);

    return (
        <section className="relative w-full ">
            <div className="swiper swiper-masthead">
                <div className="swiper-wrapper h-screen">
                    {images.map((image, index) => {
                        return (
                            <div className="swiper-slide" key={index}>
                                <GatsbyImage
                                    quality={100}
                                    image={image.src}
                                    alt={image.alt}
                                    className="w-full h-full object-cover object-center"
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <a
                href="#introduction"
                className="absolute bottom-8 left-1/2 inline-block w-12 transform -translate-x-1/2 z-10"
            >
                <img src={chevronSrc} alt="Chevron down icon" />
            </a>
        </section>
    );
};

export default Masthead;
