import React from 'react';

const Video = ({ id, type = 'vimeo' }) => {
    let url;

    if (type === 'vimeo') {
        url = `https://player.vimeo.com/video/${id}?h=0c9ebbcc37&color=ffffff&title=0&byline=0&portrait=0`;
    } else if (type === 'youtube') {
        url = `https://www.youtube.com/embed/${id}?rel=0`;
    }

    return (
        <iframe 
            src={url}
            className="w-full"
            style={{ aspectRatio: '16 / 9' }}
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
        ></iframe>
    )
}

export default Video
