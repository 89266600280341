import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../components/header';
import Footer from '../components/footer';

const Layout = ({ children }) => {
    const { site, opengraph } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    siteUrl
                    author
                }
            }
            opengraph: file(relativePath: {eq: "open-graph.jpeg"}) {
                publicURL
            }
        }
    `)

    return (
        <>
            <Helmet>
                <title>{ site.siteMetadata.title }</title>
                <meta name="description" content={ site.siteMetadata.description }/>
                <meta name="keywords" content={ site.siteMetadata.keywords }/>

                <meta name="og:title" content={ site.siteMetadata.title }/>
                <meta name="og:description" content={ site.siteMetadata.description }/>
                <meta name="og:type" content="website"/>
                <meta name="og:image" content={ opengraph.publicURL }/>
                <meta name="og:url" content={ site.siteMetadata.siteUrl }/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:creator" content={ site.siteMetadata.title }/>
                <meta name="twitter:title" content={ site.siteMetadata.title }/>
                <meta name="twitter:description" content={ site.siteMetadata.description }/>
            </Helmet>

            <Header/>
            <main className="font-body">{children}</main>
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
