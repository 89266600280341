import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const Testimonials = ({ spacingClasses = null }) => {
    const data = useStaticQuery(graphql`
        query {
            testimonials: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { glob: "**/content/testimonials/*.md" }
                }
            ) {
                edges {
                    node {
                        frontmatter {
                            client
                            text
                        }
                    }
                }
            }
        }
    `);

    const testimonials = data.testimonials.edges.map((testimonial) => {
        return {
            client: testimonial.node.frontmatter.client,
            text: testimonial.node.frontmatter.text,
        };
    });



    return (
        <section
            className={`flex min-h-screen bg-secondary testimonials ${spacingClasses}`}
        >
            <div className="container">
                <Swiper
                    modules={[Navigation, A11y]}
                    navigation
                    slidesPerView={1}
                    className="h-full"
                >
                    {testimonials.map((testimonial, index) => {
                        return (
                            <SwiperSlide key={index} className="flex items-center w-full">
                                <blockquote className="mx-auto p-5 max-w-5xl text-center text-xl font-bold md:text-2xl lg:py-12 lg:text-4xl lg:leading-snug">
                                    {testimonial.text}

                                    <footer className="mt-4">
                                        <cite className="uppercase text-xs font-normal tracking-wide not-italic lg:text-sm">{testimonial.client}</cite>
                                    </footer>
                                </blockquote>
                            </SwiperSlide>

                        );
                    })}
                </Swiper>
            </div>
        </section>
    );
};

export default Testimonials;
