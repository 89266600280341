import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import Heading from '../components/heading';
import Accordion from '../components/accordion';

const Team = ({ spacingClasses = null }) => {
    const data = useStaticQuery(graphql`
        query {
            teamMembers: allMarkdownRemark(
                filter: { fileAbsolutePath: { glob: "**/content/team/*.md" } }
                sort: { fields: frontmatter___order, order: ASC }
            ) {
                edges {
                    node {
                        html
                        frontmatter {
                            name
                            position
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 800, height: 600)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const teamMembers = data.teamMembers.edges.map((teamMember) => {
        return {
            title: teamMember.node.frontmatter.name,
            subtitle: teamMember.node.frontmatter.position,
            image: getImage(teamMember.node.frontmatter.image),
            imageAlt: `Team member photo of ${teamMember.node.frontmatter.name}`,
            text: teamMember.node.html,
        };
    });

    return (
        <section className={spacingClasses ?? ''}>
            <Heading id="team" text="Meet the team" />

            <div className="container">
                <p className="mb-6 font-bold text-xl md:w-3/4 md:text-3xl lg:mb-10 lg:font-normal lg:text-xl lg:w-1/2 lg:leading-relaxed">
                    With a wealth of experience and a passion for creating
                    exceptional spaces, we take pride in collaborating closely
                    with clients and project teams to shape environments that
                    stand out and foster a sense of belonging.
                </p>
            </div>

            <Accordion
                items={teamMembers}
                containerClasses="grid gap-y-6 lg:grid-cols-2 lg:gap-x-8 lg:gap-y-8 xl:gap-x-14 xl:gap-y-10 team"
                contentClasses="lg:absolute lg:top-0 lg:left-0 lg:px-6 lg:py-8 lg:bg-black lg:bg-opacity-75 lg:w-full lg:h-full lg:text-white content"
                subtitleClasses="lg:mb-6 lg:text-base lg:font-bold xl:text-lg 2xl:mb-8"
                textClasses="lg:text-sm xl:text-lg 2xl:text-xl"
                titleInsideContentOnDesktop={true}
            />
        </section>
    );
};

export default Team;
