import React, { useState, useEffect, useRef } from 'react';

const PrimaryNav = ({ active, scrollState }) => {
    const primaryNav = useRef();

    const defaultClasses = [
        'fixed', 'top-0', 'left-0', 'bg-black', 'bg-opacity-50', 'px-3', 'py-header', 'w-full', 'h-screen', 'transform', 'transition-transform', 'duration-500', '-z-1', 
        'lg:static', 'lg:visible', 'lg:self-end', 'lg:bg-transparent', 'lg:p-0', 'lg:w-auto', 'lg:h-auto', 'lg:transform-none', 'lg:z-0'
    ];
    const activeClasses = ['translate-y-0'];
    const inactiveVisibilityClass = ['invisible'];
    const inactiveTransitionClass = ['-translate-y-full'];

    const [classes, setClasses] = useState(defaultClasses.concat(inactiveVisibilityClass, inactiveTransitionClass));

    useEffect(() => {
        if (window.innerWidth < 1024) {
            const handleTransitionEnd = () => {
                setClasses(defaultClasses.concat(inactiveVisibilityClass, inactiveTransitionClass));
            }

            if (active) {
                setClasses(defaultClasses.concat(activeClasses));
            } else {
                primaryNav.current.addEventListener('transitionend', handleTransitionEnd);
                setClasses(defaultClasses.concat(inactiveTransitionClass));
            }

            return () => {
                primaryNav.current.removeEventListener('transitionend', handleTransitionEnd);
            }
        }
    }, [active]);

    const links = [
        {
            text: 'Projects',
            url: '#projects'
        },
        {
            text: 'What We Do',
            url: '#what-we-do'
        },
        {
            text: 'Sustainability',
            url: '#sustainability'
        },
        {
            text: 'Team',
            url: '#team'
        },
        {
            text: 'Our Clients',
            url: '#clients'
        },
        {
            text: 'Contact',
            url: '#contact'
        }
    ];

    return (
        <div ref={primaryNav} className={classes.join(' ')}>
            <nav className="flex flex-col justify-center h-full lg:flex-row lg:justify-start lg:h-auto">
                <ul className="grid gap-y-3 text-center lg:grid-flow-col lg:auto-cols-max lg:gap-x-6 lg:gap-y-0 lg:text-left">
                    {links.map((link, index) => {
                        return (
                            <li key={index} className="lg:inline-block">
                                <a 
                                    href={link.url} 
                                    className={`block text-3xl text-white uppercase font-body lg:inline-block lg:py-0 lg:text-lg ${scrollState ? 'lg:text-black' : 'lg:text-white'} lg:transition-color lg:duration-200`}
                                >
                                    {link.text}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    )
}

export default PrimaryNav
