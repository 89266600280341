import React from 'react';

import Layout from '../components/layout';
import Masthead from '../components/masthead';
import LeadText from '../components/lead-text';
import Projects from '../components/projects';
import WhatWeDo from '../components/what-we-do';
import Testimonials from '../components/testimonials';
import Sustainability from '../components/sustainability';
import NewsletterSignup from '../components/newsletter-signup';
import Team from '../components/team';
import Clients from '../components/clients';
import Contact from '../components/contact';

const IndexPage = () => {
    const marginClasses = ['mb-12', 'lg:mb-16'];
    const paddingClasses = ['lg:pb-12'];

    return (
        <Layout>
            <Masthead/>
            <LeadText/>
            <Projects/>
            <WhatWeDo spacingClasses={marginClasses.concat(paddingClasses).join(' ')}/>
            <Testimonials/>
            <Sustainability/>
            <NewsletterSignup/>
            <Team/>
            <Clients spacingClasses={paddingClasses.join(' ')}/>
            <Contact/>
        </Layout>
    )
}

export default IndexPage
