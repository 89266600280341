import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import MenuToggle from '../components/menu-toggle';
import PrimaryNav from '../components/primary-nav';

const Header = () => {
    
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "single-line-logo.svg" }) {
                publicURL
            }
            logoWhite: file(relativePath: { eq: "single-line-logo-white.svg" }) {
                publicURL
            }
        }
    `)

    const logoSrc = data.logo.publicURL;
    const logoWhiteSrc = data.logoWhite.publicURL;

    const [toggleState, setToggleState] = useState(false);
    const [scrollState, setScrollState] = useState(false); 

    useEffect(() => {
        let lastKnownScrollPosition = 0;
        let ticking = false;

        const handleScroll = scrollY => {

            if (scrollY >= 60 && !scrollState) {
                setScrollState(true);
            } else if (scrollY < 60 && scrollState) {
                setScrollState(false);
            }
        }

        window.addEventListener('scroll', function(e) {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    handleScroll(lastKnownScrollPosition);
                    ticking = false;
                });

                ticking = true;
            }
        });
    });

    return (
        <header className={`fixed top-0 w-full z-10 transition-colors duration-300 ease-in-out lg:z-20 ${scrollState ? 'bg-secondary' : 'bg-transparent'}`}>
            <div className={`container`}>
                <div className={`flex justify-between border-b ${scrollState ? 'border-secondary' : 'border-white'} pt-6 pb-3 transition-colors duration-300 ease-in-out lg:items-center`}>
                    <button onClick={() => window.scrollTo(0, 0)} className="inline-block mr-6 w-full max-w-xxs md:max-w-sm">
                        <img 
                            src={scrollState && !toggleState ? logoSrc : logoWhiteSrc}
                            alt="Logo" 
                            className="w-full"
                        />
                    </button>

                    <MenuToggle 
                        toggleState={toggleState} 
                        setToggleState={setToggleState}
                        scrollState={scrollState}
                    />

                    <PrimaryNav active={toggleState} scrollState={scrollState}/>
                </div>
            </div>
        </header>
    )
}

export default Header
