import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Heading from '../components/heading';

const Clients = ({ spacingClasses = null }) => {
    const clients = ['Legal & General','The Collective','Quintain','Renaker','Long Harbour','Way of Life','DTZ Investors','Hammerson','FOLK','URW','Conygar','Greystar','Europa','Invesco','British Land'];
    
    const orderedClients = clients.sort();
    
    return (
        <section className={spacingClasses ?? ''}>
            <Heading id="clients" text="Our Clients"/>

            <div className="grid grid-cols-1 py-8 container lg:grid-cols-12 lg:gap-x-8 lg:py-0">
                <div className="lg:col-span-7 lg:pb-32">
                    <ul className="flex flex-wrap md:pr-16 ">
                        {orderedClients.map((client, index) => (
                            <li className="inline font-bold text-3xl lg:font-normal lg:text-5xl xl:text-6xl" key={index}>
                                {client}<span className={`mx-2 lg:mx-3 ${index === clients.length - 1 ? 'hidden' : ''}`} aria-hidden="true">/</span>
                            </li>
                        ))}
                    </ul>   
                </div>

                <div className="hidden lg:block lg:col-span-5">
                    <StaticImage 
                        src="../images/masthead.png" 
                        alt="" 
                        className="w-full h-full object-fit object-cover"
                    />
                </div>
            </div>
        </section>
    )
}

export default Clients
