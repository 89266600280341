import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Heading from './heading';
import AnimateHeight from 'react-animate-height';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const Sustainability = ({ spacingClasses = null }) => {
    const data = useStaticQuery(graphql`
        query {
            imageOne: file(relativePath: { eq: "b-corp.jpeg" }) {
                publicURL
            }
            images: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { glob: "**/content/responsibility/*.md" }
                }
                sort: { fields: frontmatter___order, order: ASC }
            ) {
                edges {
                    node {
                        frontmatter {
                            alt
                            src {
                                childImageSharp {
                                    gatsbyImageData(width: 900)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const images = data.images.edges.map((image) => {
        return {
            src: getImage(image.node.frontmatter.src),
            alt: image.node.frontmatter.alt,
        };
    });

    const paragraphClasses = 'text-lg lg:text-xl';

    const showLink = false;

    const subSections = [
        {
            title: 'Aligned with your Goals',
            paragraph:
                "We are committed to collaborating with you to achieve your sustainability ambitions and broader ESG goals. Our expertise in innovative materials and products continuously evolves, and we provide guidance on hyper-local sourcing, low carbon materials and circular design to help minimise your project's environmental impact, reduce waste and promote social value.",
        },
        {
            title: 'Carbon Tracking',
            paragraph:
                'With our data-driven insights, we track the embodied carbon of your furniture, empowering you to make informed choices that align with your sustainability goals and contribute to a more sustainable future.',
        },
        {
            title: 'Rooted in Community',
            paragraph:
                "Collaborating with local businesses, suppliers and communities, we support you in creating positive social impacts through your development. From collaborating with local artists, to crafting unique accessory packs, we seamlessly blend the community's DNA with your project, fostering a sense of belonging and making a meaningful difference.",
        },
        {
            title: 'Refresh with Positive Impact',
            paragraph:
                "When it comes to refreshes, TFP ensures maximum value by expertly managing the entire process, assessing furniture for re-use, resale or donation to charities. We collaborate with our partners to keep furniture in use for as long as possible, or align with your brand's own sustainability initiatives, offering solutions that are in line with your values.",
        },
    ];

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const swiper = new Swiper('.swiper-sustainability', {
            modules: [Autoplay, EffectFade],
            autoplay: {
                delay: 5000,
            },
            loop: true,
            effect: 'fade',
        });
    }, []);

    return (
        <section className={spacingClasses ?? ''}>
            {/* Text changed from 'responsibility' to 'sustainability' as per client request */}
            <Heading id="sustainability" text="sustainability" />

            <div className="pt-8 grid container lg:grid-cols-12 lg:grid-rows-12  lg:gap-x-8">
                <div className="flex flex-col justify-between space-y-10 lg:col-span-6 lg:space-y-16">
                    <div className="sm:max-w-2/3 lg:max-w-3xl ">
                        <h3 className="mb-6 text-3xl font-bold  lg:mb-12 lg:text-6xl lg:font-normal ">
                            Our ongoing commitment
                        </h3>

                        <p className={`mb-3 ${paragraphClasses}`}>
                            Sustainability is deeply ingrained in TFP's values
                            and throughout our operations, extending from the
                            manufacturers we work with and products we source to
                            ensuring the wellbeing and development of our team
                            and the communities we serve.
                        </p>
                        <h3 className="mb-3 text-3xl font-bold lg:font-normal ">
                            B Corp Certified
                        </h3>
                        <p className={paragraphClasses}>
                            Our B Corp certification solidifies our commitment
                            to sustainability, and we take pride in driving a
                            more sustainable and equitable future for our
                            industry and beyond. Collaborating closely with
                            clients, project teams and manufacturers, we strive
                            to achieve impactful results that leave a positive
                            mark on people, communities and the planet.
                        </p>

                        <p className={paragraphClasses}>
                            With a dedicated sustainability department, we offer
                            expert consulting services to help you clarify the
                            holistic sustainability impact of your project. From
                            the very beginning, we work with you to identify
                            ways to make improvements and achieve your
                            sustainability goals.
                        </p>

                        {showLink && (
                            <p className="mt-3 font-bold lg:text-xl">
                                To find out more about ReForm,{' '}
                                <a href="#" className="underline">
                                    get in touch
                                </a>
                            </p>
                        )}
                    </div>

                    <div>
                        <button
                            onClick={() => setIsVisible(!isVisible)}
                            className="flex items-center font-bold text-lg lg:text-xl"
                        >
                            {isVisible ? 'Show less' : 'Show more'}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 448 512"
                                className="ml-3 h-6"
                            >
                                {isVisible ? (
                                    <path d="M432 272H416L32 272H16l0-32 16 0 384 0 16 0v32z" />
                                ) : (
                                    <path d="M240 64V48H208V64 240H32 16v32H32 208V448v16h32V448 272H416h16V240H416 240V64z" />
                                )}
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="row-start-1 pb-12 lg:col-span-6 lg:col-start-7 lg:pb-0">
                    <div className="swiper swiper-sustainability h-full">
                        <div className="swiper-wrapper">
                            {images.map((image, index) => (
                                <div
                                    className="relative swiper-slide w-full h-full"
                                    key={index}
                                >
                                    <GatsbyImage
                                        quality={100}
                                        image={image.src}
                                        alt={image.alt}
                                        className="aspect-square w-full lg:absolute lg:inset-0 lg:aspect-auto lg:object-cover lg:object-center lg:h-full"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <AnimateHeight duration={300} height={isVisible ? 'auto' : 0}>
                <div className="container mt-16 grid gap-8 md:grid-cols-2 xl:grid-cols-4 xl:gap-10">
                    {subSections.map((section, index) => (
                        <div className="flex flex-col" key={index}>
                            <h4 className="font-bold text-2xl pb-4">
                                {section.title}
                            </h4>

                            <p>{section.paragraph}</p>
                        </div>
                    ))}
                </div>
            </AnimateHeight>
        </section>
    );
};

export default Sustainability;
