import React from 'react';

import Heading from './heading';

const Contact = () => {
    const emailAddress = 'BTR@thefurniturepractice.com';
    const telephone = '020 7549 5454';
    const financeEmailAddress = 'finance@thefurniturepractice.com';
    const financeTelephone = '020 8156 6336';
    const supplierEmailAddress = 'confirmations@thefurniturepractice.com';
    const supplierTelephone = '020 7549 5454';

    const formatMailToUrl = (emailAddress, subject) => {
        return `mailto:${emailAddress.toLowerCase()}?subject=${encodeURIComponent(
            subject
        )}`;
    };

    const formatTelUrl = (telephoneNumber) => {
        return `tel:${telephoneNumber.replace(' ', '')}`;
    };

    const headingClasses = 'mb-4 text-2xl lg:mb-6 lg:text-4xl';
    const contactDetailClasses = 'block text-2xl underline lg:text-4xl';

    return (
        <section className="flex flex-col bg-secondary pb-24">
            <Heading id="contact" text="Contact" />

            <div className="flex flex-grow justify-center items-center container">
                <div className="grid gap-y-8 w-full max-w-4xl py-12 lg:gap-y-16 lg:py-24 lg:text-center">
                    <h3 className="text-3xl font-bold lg:text-6xl lg:font-normal">
                        Bring your space to life with us.
                    </h3>

                    <p className="text-3xl lg:text-4xl lg:leading-snug">
                        If you are interested in collaborating with us and want
                        to hear more about our team and services, we would love
                        to hear from you.
                    </p>

                    <div>
                        <h4 className={headingClasses}>
                            For general enquiries
                        </h4>
                        <a
                            href={formatMailToUrl(
                                emailAddress,
                                'General Enquiry'
                            )}
                            className={`mb-3 break-words  ${contactDetailClasses}`}
                        >
                            {emailAddress}
                        </a>
                        <a
                            href={formatTelUrl(telephone)}
                            className={contactDetailClasses}
                        >
                            {telephone}
                        </a>
                    </div>

                    <div>
                        <h4 className={headingClasses}>
                            For finance enquiries
                        </h4>
                        <a
                            href={formatMailToUrl(
                                financeEmailAddress,
                                'Finance Enquiry'
                            )}
                            className={`mb-3 break-words ${contactDetailClasses}`}
                        >
                            {financeEmailAddress}
                        </a>
                        <a
                            href={formatTelUrl(financeTelephone)}
                            className={contactDetailClasses}
                        >
                            {financeTelephone}
                        </a>
                    </div>

                    <div>
                        <h4 className={headingClasses}>
                            For supplier related enquiries
                        </h4>
                        <a
                            href={formatMailToUrl(
                                supplierEmailAddress,
                                'Supplier Enquiry'
                            )}
                            className={`mb-3 break-words ${contactDetailClasses}`}
                        >
                            {supplierEmailAddress}
                        </a>
                        <a
                            href={formatTelUrl(supplierTelephone)}
                            className={contactDetailClasses}
                        >
                            {supplierTelephone}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
