import React from 'react';

const Heading = ({ id, text }) => {
    
    return (
        <div className="container">
            <h2 id={id} className="mb-6 pt-24 pb-4 border-b border-gray-400 text-2xl font-bold uppercase lg:text-4xl lg:mb-8 lg:pb-6">
                {text}
            </h2>
        </div>
    )
}

export default Heading
