import React, { useState } from 'react';

const NewsletterSignup = () => {

    const uid = '72fff05d6ce2dbaf4836645f6';
    const id = '68a6e8aa4b';
    const formActionUrl = 'https://thefurniturepractice.us2.list-manage.com/subscribe/post';
    const termsPrivacyUrl = 'https://furniturepractice.app.box.com/s/777b0bnu5yaa4p8eifehav3k33mzv3qf';

    const [email, setEmail] = useState('');

    return (
        <section className="mt-24 py-16 bg-secondary md:py-18">
            <div className="container md:max-w-5xl">
                <div className="flex flex-col mb-12 md:flex-row md:justify-center md:items-center">
                    <p className="mb-4 text-center md:mb-0 md:pr-16 md:text-left">Subscribe for monthly updates featuring new projects and inspiration</p>

                    <form action={formActionUrl} 
                          method="POST" 
                          target="_blank"
                          noValidate
                          className="flex border-b-2 border-black validate md:mb-0 md:w-80">

                        <input type="hidden" name="u" value={uid}/>
                        <input type="hidden" name="id" value={id}/>

                        <input id="mce-EMAIL"
                               type="email"
                               name="EMAIL" 
                               value={email}
                               onChange={(e) => setEmail(e.target.value)} 
                               placeholder="Enter your email address"
                               className="bg-transparent p-3 w-full"/>

                        <input id="mc-embedded-subscribe"
                               type="submit" 
                               value="Ok" 
                               name="subscribe"  
                               className="bg-transparent p-3 uppercase"/>

                        {/* Anti-spam protection */}
                        <div style={{position: 'absolute', left: '-5000px'}} 
                             aria-hidden="true"
                             aria-label="Please leave the following fields empty">
                            <input type="text" 
                                   name="b_72fff05d6ce2dbaf4836645f6_68a6e8aa4b" 
                                   tabIndex="-1" 
                                   defaultValue=""/>
                        </div>
                    </form>
                </div>

                <p className="text-center text-xs text-gray-400 md:text-sm">
                    Review TFP's Terms & Conditions & Privacy Policy <a href={termsPrivacyUrl} target="_blank" className="underline">Terms & Conditions and Privacy Policy.*</a>
                </p>
            </div>
        </section>
    )
}

export default NewsletterSignup
