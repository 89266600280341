import React from 'react';

const MenuToggle = ({ toggleState, setToggleState, scrollState }) => {

    return (
        <button 
            onClick={() => setToggleState(!toggleState)} 
            className={`burger-menu flex-shrink-0 lg:hidden ${toggleState ? 'open' : ''} ${scrollState && !toggleState ? 'scrolled' : ''}`}
        >
            <span></span>
            <span></span>
            <span></span>
        </button>
    )
}

export default MenuToggle
