import React from 'react';

const LeadText = () => {

    return (
        <section id="introduction" className="flex justify-center items-center min-h-screen">
            <div className="grid gap-y-8 max-w-6xl text-center container lg:gap-y-16">
                <p className="leading-tight lg:leading-snug text-3xl md:text-4xl lg:text-5xl">
                    We are an international furniture specialist, collaborating with developers and design teams to create unique spaces that residents and guests love. 
                </p>
                <p className="leading-normal md:text-lg lg:text-2xl">
                    From vibrant <strong>build-to-rent</strong> developments and <strong>co-living</strong> projects to dynamic <strong>student accommodations</strong> and <strong>aparthotels</strong>, we work closely with developers, operators and project teams to craft meaningful spaces that deeply resonate with residents and guests. With a passionate and experienced team, our design-driven and future-facing approach generates long-term value for investors, while making a positive impact on people, communities and the planet.
                </p>
            </div>
        </section>
    )
}

export default LeadText
