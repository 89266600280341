import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            marque: file(relativePath: { eq: "marque.svg" }) {
                publicURL
            }
            bCorp: file(relativePath: { eq: "bcorp.svg" }) {
                publicURL
            }
        }
    `);

    const marqueSrc = data.marque.publicURL;
    const bCorpSrc = data.bCorp.publicURL;

    const offices = [
        {
            title: 'London',
            address: {
                lineOne: '31 Pear Tree Street, London,',
                lineTwo: 'EC1V 3AG',
            },
            number: {
                url: '+442075495454',
                text: '+44 (0) 20 7549 5454',
            },
        },
        {
            title: 'New York',
            address: {
                lineOne: 'NeueHouse Madison Square, 110 East',
                lineTwo: '250th Street, New York, 10010',
            },
            number: {
                url: '+19173100679',
                text: '+1 917 310 0679',
            },
        },
        {
            title: 'Manchester',
            address: {
                lineOne: '37 King Street, Manchester,',
                lineTwo: 'M2 7AT',
            },
            number: {
                url: '+441615095750',
                text: '+44 (0) 16 1509 5750',
            },
        },
        {
            title: 'The Furniture Practice B.V',
            address: {
                lineOne: 'Rijksweg 79',
                lineTwo: '1411GE Naarden',
                lineThree: 'Netherlands',
            },
            number: {
                url: '',
                text: '',
            },
        },
    ];

    return (
        <footer className="bg-white pt-14">
            <div className="container">

                <div className="grid gap-y-8 border-t border-b border-gray-400 py-10 lg:grid-cols-5 lg:gap-x-6 lg:pt-12">
                    <div className="space-y-4">
                        <div className="flex items-center space-x-8">
                            <img
                                src={marqueSrc}
                                alt="Logo"
                                className="w-16"
                            />
                            <a
                                href="https://www.thefurniturepractice.com/journal/we-are-a-certified-b-corp"
                                className=""
                            >
                                <img
                                    src={bCorpSrc}
                                    alt="B Corp logo"
                                    className="w-10 lg:w-12"
                                />
                            </a>
                        </div>

                        <div className="font-bold">
                            <a href="https://www.linkedin.com/company/the-furniture-practice">
                                Linkedin
                            </a>
                            
                            <span className="mx-1 lg:mx-2">|</span>

                            <a href="https://www.instagram.com/the_furniture_practice/">
                                Instagram
                            </a>
                        </div>
                    </div>

                    {offices.map((office, index) => {
                        return (
                            <div className="lg:col-span-1" key={index}>
                                <p className="font-bold">{office.title}</p>

                                <a
                                    href={`tel:${office.number.url}`}
                                    className="block break-all"
                                >
                                    {office.number.text}
                                </a>

                                <address className="mb-4 not-italic">
                                    {office.address.lineOne}
                                    <br />
                                    {office.address.lineTwo}
                                    <br />
                                    {office.address.lineThree}
                                </address>
                            </div>
                        );
                    })}
                </div>

                <div className="flex flex-col items-center gap-y-1 py-6 text-xs text-gray-400 sm:flex-row sm:justify-between sm:gap-y-0">
                    <div>
                        <a
                            href="https://www.thefurniturepractice.com/privacy-policy"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>

                        <span className="mx-1 lg:mx-2">|</span>

                        <a
                            href="https://www.thefurniturepractice.com/terms-conditions"
                            target="_blank"
                        >
                            Terms &amp; Conditions
                        </a>
                    </div>

                    <span className="block">
                        &copy; {new Date().getFullYear()} The Furniture Practice
                    </span>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
