import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import AnimateHeight from 'react-animate-height';
import Heading from '../components/heading';

const WhatWeDo = ({ spacingClasses = null }) => {
    
    const data = useStaticQuery(graphql`
        query {
            whatWeDo: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { glob: "**/content/what-we-do/*.md" }
                }
                sort: { fields: frontmatter___order, order: ASC }
            ) {
                edges {
                    node {
                        html
                        frontmatter {
                            title
                        }
                    }
                }
            }
            blackhorseMillsPdf: file(
                relativePath: { eq: "projects/documents/blackhorse_mills.pdf" }
            ) {
                publicURL
            }
        }
    `);

    const whatWeDoData = data.whatWeDo.edges.map((map) => ({
        title: map.node.frontmatter.title,
        text: map.node.html
    }));

    const [activeIndex, setActiveIndex] = useState();
    
    const marqueeText = 'Build-to-Rent / Co-Living / Apart-Hotels / Single-Family Homes / Student Accommodation / Senior Living / Hospitality / '

    return (
        <section className={spacingClasses ?? ''}>
            <Heading id="what-we-do" text="What We Do" />

            <div className="grid gap-y-8 container lg:grid-cols-2 lg:gap-x-8 xl:gap-12">
                <div className="space-y-4 md:space-y-8">
                    <h3 className="text-3xl font-bold md:text-4xl lg:text-6xl lg:font-normal">
                        An integrated approach, from concept to completion.
                    </h3>
                </div>

                <div className="row-start-1 lg:row-span-2">
                    <StaticImage 
                        src="../../content/what-we-do/images/what-we-do-image.jpg" 
                        alt="Bed with side table, cushions and lamp" 
                        className=" w-full h-full object-fit aspect-square"
                    />
                </div>

                <div>
                    {whatWeDoData.map((data, index) => (
                        <article 
                            className="border-b-2 border-black"
                            key={index}
                            role="region"
                        >
                            <h3 className="font-bold sm:text-lg md:text-xl xl:text-2xl">
                                <button 
                                    onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                                    className="flex items-center space-x-4 justify-between py-4 w-full text-left"
                                    aria-expanded={activeIndex === index}
                                >
                                    <span>{data.title}</span>

                                    <div className="inline-flex items-center cursor-pointer text-base" aria-hidden="true">
                                        <span className="hidden mr-4 whitespace-nowrap md:inline">
                                            {activeIndex === index ? 'See less' : 'See more'}
                                        </span>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            {activeIndex === index ? (
                                                <path d="M432 240H416L32 240H16l0-32 16 0 384 0 16 0v32z" />
                                            ) : (
                                                <path d="M240 64V48H208V64 240H32 16v32H32 208V448v16h32V448 272H416h16V240H416 240V64z" />
                                            )}
                                        </svg>
                                    </div>
                                </button>
                            </h3>

                            <AnimateHeight
                                duration={300}
                                height={activeIndex === index ? 'auto' : 0}
                            >
                                <div className="prose text-black max-w-none pb-4"
                                    dangerouslySetInnerHTML={{ __html: data.text }}>
                                
                                </div>
                            </AnimateHeight>
                        </article>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhatWeDo;
