import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Accordion = ({
    items,
    containerClasses = null,
    contentClasses = null,
    titleClasses = null,
    textClasses = null,
    titleInsideContentOnDesktop = false,
}) => {
    const data = useStaticQuery(graphql`
        query {
            chevron: file(relativePath: { eq: "chevron-down-grey.svg" }) {
                publicURL
            }
        }
    `);

    const chevronSrc = data.chevron.publicURL;

    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        index !== activeIndex ? setActiveIndex(index) : setActiveIndex(-1);
    };

    return (
        <div className={`w-full container ${containerClasses ?? ''}`}>
            {items.map((item, index) => {
                return (
                    <div className="relative item" key={index}>
                        <div
                            onClick={() => handleClick(index)}
                            className="cursor-pointer lg:cursor-auto"
                        >
                            {item.image && (
                                <GatsbyImage
                                    image={item.image}
                                    alt={item.imageAlt}
                                    className="mb-6 w-full lg:mb-0"
                                />
                            )}

                            <div className="flex justify-between">
                                <h3
                                    className={`text-2xl font-bold lg:text-3xl ${
                                        titleInsideContentOnDesktop
                                            ? 'lg:hidden'
                                            : ''
                                    } ${titleClasses ?? ''}`}
                                >
                                    {item.title}
                                </h3>

                                <img
                                    src={chevronSrc}
                                    alt="Chevron icon"
                                    className={`w-6 transform ${
                                        activeIndex === index
                                            ? '-rotate-180'
                                            : 'rotate-0'
                                    } transition-transform duration-200 lg:hidden`}
                                />
                            </div>
                        </div>

                        <div
                            className={`${
                                activeIndex === index
                                    ? 'visible h-auto'
                                    : 'invisible h-0'
                            } lg:visible lg:h-auto ${contentClasses ?? ''}`}
                        >
                            {titleInsideContentOnDesktop && (
                                <div>
                                    <h3
                                        className={`hidden lg:text-2xl lg:font-bold lg:block ${
                                            titleClasses ?? ''
                                        }`}
                                    >
                                        {item.title}
                                    </h3>
                                    <p className="py-2 xl:pt-4 xl:pb-8 font-bold">
                                        {item.subtitle}
                                    </p>
                                </div>
                            )}

                            <div
                                className={`text-lg ${textClasses ?? ''}`}
                                dangerouslySetInnerHTML={{ __html: item.text }}
                            ></div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion;
