import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Heading from '../components/heading';
import ConditionalWrapper from '../components/conditional-wrapper';
import Video from '../components/video.js';

const Project = ({ project }) => {
    
    const projectClasses = [
        'relative aspect-video',
        project.pdf ? 'cursor-pointer' : 'cursor-default'
    ].join(' ');

    const imageClassNames = 'w-full h-full object-cover object-center';

    const titleClasses = 'font-bold text-xl';

    const clientClasses = 'text-lg';

    return (
        <ConditionalWrapper
            condition={project.pdf}
            wrapper={(children) => (
                <a
                    href={project.pdf}
                    className={projectClasses}
                    target="_blank"
                    rel="noreferrer"
                >
                    {children}
                </a>
            )}
            secondaryWrapper={(children) => (
                <div className={projectClasses}>{children}</div>
            )}
        >
            <>
                <GatsbyImage
                    image={project.image}
                    className={imageClassNames}
                    alt={project.title}
                />

                <div className="mt-3 space-y-1 w-full lg:flex lg:justify-between lg:space-y-0 lg:space-x-4">
                    <h3 className={titleClasses}>
                        {`${project.title}, ${project.client}`}
                    </h3>

                    <p className={clientClasses + ' ml-auto whitespace-nowrap'}>
                        {project.sector}
                    </p>
                </div>
            </>
        </ConditionalWrapper>
    );
};

const Projects = ({ spacingClasses = null }) => {
    const data = useStaticQuery(graphql`
        query {
            projects: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { glob: "**/content/projects/*.md" }
                }
                sort: { fields: frontmatter___order, order: ASC }
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            title
                            client
                            sector
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 1200)
                                }
                            }
                            pdf {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    `);

    const projects = data.projects.edges.map((project) => {
        return {
            path: project.node.frontmatter.path,
            title: project.node.frontmatter.title,
            client: project.node.frontmatter.client,
            sector: project.node.frontmatter.sector,
            image: getImage(project.node.frontmatter.image),
            pdf: project.node.frontmatter?.pdf?.publicURL,
        };
    });

    const projectsPerPage = 6;
    const [visibleProjects, setVisibleProjects] = useState(projects.slice(0, projectsPerPage));

    const handleShowMore = () => {
        const moreProjects = projects.slice(0, visibleProjects.length + projectsPerPage);
        setVisibleProjects(moreProjects);
    }

    return (
        <section className={spacingClasses ?? ''}>
            <Heading id="projects" text="Projects" />

            <div className="grid grid-cols-1 gap-y-8 container lg:gap-y-10">
                <div>
                    <Video type="youtube" id="VDVn0EsreS0" />

                    <a
                        href={projects[2].pdf}
                        target="_blank"
                        rel="noreferrer"
                        className="block mt-2 text-center underline md:text-lg lg:text-xl"
                    >
                        View The Gessner Project Case Study
                    </a>
                </div>

                <div className="grid grid-cols-1 gap-y-6 lg:grid-cols-2 lg:gap-10">
                    {visibleProjects.map((project, index) =>
                        <Project project={project} featured={true} key={index}/>
                    )}
                </div>

                {visibleProjects.length < projects.length &&
                    <div>
                        <button
                            onClick={() => handleShowMore()}
                            className="flex items-center px-4 py-2 bg-black font-bold text-white text-lg transition-colors duration-200 ease-in-out hover:bg-opacity-80 focus:bg-opacity-80 lg:text-xl"
                        >
                            Show More Projects
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 448 512"
                                className="ml-3 h-6"
                            >
                                <path d="M240 64V48H208V64 240H32 16v32H32 208V448v16h32V448 272H416h16V240H416 240V64z" fill="white" />
                            </svg>
                        </button>
                    </div>
                }

                <div>
                    <Video type="youtube" id="AVjnczgUGrM" />

                    <a
                        href={projects[8].pdf}
                        target="_blank"
                        rel="noreferrer"
                        className="block mt-2 text-center underline md:text-lg lg:text-xl"
                    >
                        View The Blackhorse Mills Project Case Study
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Projects;
